import { Grid, Typography, useMediaQuery, TextField } from '@mui/material'
import FormikTextField from './FormikTextField'

export default function ReferralContact({
  formik,
  urlEmail,
  optionalFields = false,
  institution = false
}: any) {
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))

  return (
    <>
      <Grid item xs={12}>
        {formik.values.referralLink !== 'beneficiaire' && (
          <Typography
            component="h3"
            variant={isSm ? 'h6' : 'h5'}
            color="primary"
            display={'inline-block'}
            lineHeight={'unset'}
            marginLeft={'5px'}
            marginTop={'15px'}
            marginBottom={'15px'}
          >
            {formik.values.referralLink !== 'other'
              ? 'Vos informations de contact'
              : 'Informations du référent (famille ou assistant social)'}
          </Typography>
        )}
      </Grid>
      {optionalFields && (
        <>
          <Grid item xs={6}>
            <FormikTextField
              name="referralFirstName"
              label="Prénom"
              formik={formik}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField
              name="referralLastName"
              label="Nom"
              formik={formik}
            />
          </Grid>
        </>
      )}
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="referralPhone"
          name="referralPhone"
          label="📞 Téléphone"
          type="phone"
          value={formik.values.referralPhone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.referralPhone && Boolean(formik.errors.referralPhone)
          }
          helperText={
            formik.touched.referralPhone && formik.errors.referralPhone
          }
        />
      </Grid>
      <Grid item xs={6}>
        {/* {institution && (
          <>
            <Grid item xs={12} marginBottom={'20px'}>
              <FormikTextField
                name="referralInstitutionName"
                label="Établissement"
                formik={formik}
              />
            </Grid>
          </>
        )} */}
        {!urlEmail && !institution && (
          <TextField
            fullWidth
            id="referralEmail"
            name="referralEmail"
            label="✉️ Email"
            type="email"
            value={formik.values.referralEmail}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.referralEmail &&
              Boolean(formik.errors.referralEmail)
            }
            helperText={
              formik.touched.referralEmail && formik.errors.referralEmail
            }
          />
        )}
      </Grid>
    </>
  )
}
