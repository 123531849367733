import { useEffect, useState } from 'react'
import {
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  Button,
  TextField,
  Snackbar,
  Alert,
  AlertTitle,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControl
} from '@mui/material'
import { useFormik } from 'formik'
import Keycloak from 'keycloak-js'
import * as yup from 'yup'
import { ThemeProvider, useTheme } from '@emotion/react'
import { searchAddress } from 'adapters/data.gouv/addressApi'
import { postFamilyLead } from 'utils/backendApi'
import AddressAutocomplete from 'components/Contact/AddressAutocomplete'
import BeneficiaryFields from 'components/Contact/BeneficiaryFields'
import FormikTextField from 'components/Contact/FormikTextField'
import Unauthorized from 'components/Unauthorized/Unauthorized'
import {
  searchPipedriveBTOBAS,
  searchPipedriveBTOBOrgs
} from 'adapters/backend/pipedrive'
import { PipedriveOrgDto, PipedrivePersonDto } from 'types/dto'
import OrgAutocomplete from 'components/Contact/OrgAutocomplete'
import ASAutocomplete from 'components/Contact/ASAutocomplete'
import ReferralContact from 'components/Contact/ReferralContact'

const validationSchema = yup.object({
  referralEmail: yup
    .string()
    .email('Merci de renseigner un email valide')
    .optional(),
  referralLink: yup
    .string()
    .required('Le lien avec le bénéficiaire est requis'),
  address: yup.string().required("L'adresse est requise"),
  firstNameBenef: yup.string().required('Le prénom du bénéficiaire est requis'),
  lastNameBenef: yup
    .string()
    .required('Le nom de famille du bénéficiaire est requis'),
  // Optional fields
  birthdayBenef: yup.string().optional(),
  financialAid: yup.string().optional(),
  daysOfIntervention: yup.array().of(yup.string()).optional(),
  hourVolume: yup.number().optional(),
  additionalNeeds: yup.array().of(yup.string()).optional(),
  services: yup.array().of(yup.string()).optional(),
  referralFirstName: yup.string().optional(),
  referralLastName: yup.string().optional(),
  startDate: yup.string().optional(),
  additionalInformation: yup.string().optional(),
  referralInstitutionName: yup.string().optional(),
  referralASName: yup.string().optional(),
  phoneBenef: yup.string().optional(),
  emailBenef: yup
    .string()
    .email('Merci de renseigner un email valide')
    .optional()
})

export default function TSForm({ keycloak }: { keycloak?: Keycloak }) {
  const searchParams = new URLSearchParams(window.location.search)
  const [addresses, setAddresses] = useState([])
  const [orgs, setOrgs] = useState<PipedriveOrgDto[]>([])
  const [asList, setAsList] = useState<PipedrivePersonDto[]>([])
  const [selectedAddress, setSelectedAddress] = useState<any>({})
  const [selectedOrg, setSelectedOrg] = useState<any>({})
  const [selectedAS, setSelectedAS] = useState<any>({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))
  const theme = useTheme()
  const [urlEmail, setUrlEmail] = useState('')
  const [openedSuccessSnackbar, setOpenedSuccessSnackbar] = useState(false)
  const [openedFailureSnackbar, setOpenedFailureSnackbar] = useState(false)
  const [establishmentName, setEstablishmentName] = useState('')
  const [establishmentId, setEstablishmentId] = useState('')
  const [asId, setAsId] = useState('')
  // const [referralEmail, setReferralEmail] = useState('')
  const [referralFirstName, setReferralFirstName] = useState('')
  const [referralLastName, setReferralLastName] = useState('')
  const [ownerBizDev, setOwnerBizDev] = useState('')
  // const [referralPhone, setReferralPhone] = useState('')

  useEffect(() => {
    if (!keycloak) {
      return
    }

    if (!keycloak.authenticated) {
      window.location.assign(keycloak.createLoginUrl())
    }

    keycloak
      .loadUserProfile()
      .then((profile: any) => {
        if (profile?.attributes && profile?.attributes?.establishment?.length) {
          setEstablishmentName(profile?.attributes?.establishment[0])
        }
        if (
          profile?.attributes &&
          profile?.attributes?.establishment_id?.length
        ) {
          setEstablishmentId(profile?.attributes?.establishment_id[0])
        }
        if (profile?.attributes && profile?.attributes?.as_id?.length) {
          setAsId(profile?.attributes?.as_id[0])
        }
        if (profile?.attributes && profile?.attributes?.as_first_name?.length) {
          setReferralFirstName(profile?.attributes?.as_first_name[0])
        }
        if (profile?.attributes && profile?.attributes?.as_last_name?.length) {
          setReferralLastName(profile?.attributes?.as_last_name[0])
        }
        if (profile?.attributes && profile?.attributes?.owner_biz_dev?.length) {
          setOwnerBizDev(profile?.attributes?.owner_biz_dev[0])
        }
        // if (profile?.attributes && profile?.attributes?.as_phone?.length) {
        //   setReferralPhone(profile?.attributes?.as_phone[0])
        // }
        // setReferralEmail(profile.email)
        // formik.setFieldValue('referralEmail', profile.email)
      })
      .catch()
  }, [keycloak])

  // const getASEmail = (as: any) => (as?.email?.length > 0 ? as?.email[0] : null)
  // const getASPhone = (as: any) => (as?.phone?.length > 0 ? as?.phone[0] : null)

  useEffect(() => {
    if (!searchParams.has('email')) {
      return
    }

    yup
      .string()
      .email()
      .validate(searchParams.get('email'))
      .then(() => {
        setUrlEmail(searchParams.get('email') || '')
        formik.setFieldValue('referralEmail', searchParams.get('email') || '')
      })
      .catch()
  }, [])

  const formik = useFormik({
    initialValues: {
      referralEmail: urlEmail,
      referralPhone: '',
      referralLink: 'other',
      address: '',
      source: '',
      firstNameBenef: '',
      lastNameBenef: '',
      birthdayBenef: '1940-01-01',
      financialAid: '',
      daysOfIntervention: [],
      hourVolume: 0,
      services: [],
      additionalNeeds: [],
      referralFirstName: undefined,
      referralLastName: undefined,
      startDate: undefined,
      additionalInformation: '',
      referralInstitutionName: '',
      referralInstitutionId: '',
      referralASName: '',
      referralASId: '',
      phoneBenef: '',
      emailBenef: ''
    },
    validationSchema,
    onSubmit: values => {
      const defaultAddress = addresses.length > 0 ? addresses[0] : ({} as any)
      const addressToSubmit =
        Object.keys(selectedAddress).length > 0
          ? selectedAddress
          : defaultAddress

      setIsSubmitting(true)
      const body = {
        referral: {
          email: values.referralEmail,
          phone: values.referralPhone,
          firstName: values.referralFirstName,
          lastName: values.referralLastName,
          relationType: values.referralLink
        },
        beneficiary: {
          firstName: values.firstNameBenef,
          lastName: values.lastNameBenef,
          address: {
            gpsLng: addressToSubmit?.geometry?.coordinates[0],
            gpsLat: addressToSubmit?.geometry?.coordinates[1],
            city: addressToSubmit?.properties?.city,
            postCode: addressToSubmit?.properties?.postcode,
            address: addressToSubmit?.properties?.label
          },
          birthday: values.birthdayBenef,
          phone: values.phoneBenef,
          email: values.emailBenef
        },
        financialAid: values.financialAid,
        daysOfIntervention: values.daysOfIntervention,
        hourVolume: values.hourVolume,
        additionalNeeds: values.additionalNeeds,
        startDate: values.startDate,
        additionalInformation: values.additionalInformation,
        services: values.services,
        referralInstitutionName: establishmentName || selectedOrg?.name,
        referralInstitutionId: String(establishmentId || selectedOrg?.id),
        referralAsId: String(asId || selectedAS?.id),
        asFirstName: referralFirstName,
        asLastName: referralLastName,
        bizOwner: ownerBizDev
      }

      postFamilyLead(body, 'ts')
        .then(() => {
          setIsSubmitting(false)
          setOpenedSuccessSnackbar(true)
          formik.resetForm()
          setAddresses([])
        })
        .catch(() => {
          setIsSubmitting(false)
          setOpenedFailureSnackbar(true)
        })
    }
  })

  useEffect(() => {
    if (!formik.values.address || formik.values.address.length <= 3) {
      return
    }

    searchAddress(formik.values.address).then((addresses): void => {
      setAddresses(addresses)
    })
  }, [formik.values.address])

  useEffect(() => {
    if (
      !keycloak?.authenticated ||
      !keycloak.hasRealmRole('edit-lead') ||
      !keycloak.token ||
      orgs.length > 0
    ) {
      return
    }

    searchPipedriveBTOBOrgs(keycloak.token).then((orgs): void => {
      setOrgs(orgs)
    })
  }, [keycloak, orgs.length])

  useEffect(() => {
    if (!selectedOrg.id) {
      return
    }

    setSelectedAS(undefined)

    searchPipedriveBTOBAS(selectedOrg.id).then((asList): void => {
      setAsList(asList)
    })
  }, [selectedOrg])

  const filterOptions = (options: any[]) => options

  // const hasOneEmail =
  //   formik.values.emailBenef !== '' || formik.values.referralEmail !== ''
  // For now: we allow any number of emails
  const hasOneEmail = true

  const hasOnePhone =
    formik.values.phoneBenef !== '' || formik.values.referralPhone !== ''

  return (
    (keycloak?.authenticated &&
      (keycloak.hasRealmRole('social_worker') ||
        keycloak.hasRealmRole('edit-lead')) && (
        <ThemeProvider theme={theme}>
          <Container style={{ padding: '20px' }}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={openedSuccessSnackbar}
              onClose={() => setOpenedSuccessSnackbar(false)}
            >
              <Alert
                onClose={() => setOpenedSuccessSnackbar(false)}
                severity="success"
                variant="filled"
                sx={{ width: '100%', color: 'white' }}
              >
                <AlertTitle>Succès</AlertTitle>
                Votre demande de contact a bien été envoyée
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={openedFailureSnackbar}
              onClose={() => setOpenedFailureSnackbar(false)}
            >
              <Alert
                onClose={() => setOpenedFailureSnackbar(false)}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
              >
                <AlertTitle>Erreur</AlertTitle>
                Une erreur est survenue
              </Alert>
            </Snackbar>
            <Grid container>
              <Paper
                style={{
                  padding: '20px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Grid
                  container
                  direction={'column'}
                  alignItems={'center'}
                  xs={8}
                >
                  <img
                    src="./img/logo-click-and-care-color.png"
                    style={{
                      height: isSm ? '50px' : '150px',
                      marginBottom: '20px'
                    }}
                  />
                  <Typography
                    component="h2"
                    variant={isSm ? 'h5' : 'h3'}
                    color="primary"
                    fontWeight={'bold'}
                    align="center"
                    display={'inline-block'}
                    paddingLeft={'10px'}
                    paddingRight={'10px'}
                    marginBottom={'25px'}
                  >
                    Formulaire d'inscription de votre bénéficiaire
                  </Typography>
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2} direction={'row'}>
                      <BeneficiaryFields
                        formik={formik}
                        optionalFields={true}
                        contactFields={true}
                      />
                      <Grid item xs={6}>
                        <AddressAutocomplete
                          addresses={addresses}
                          setSelectedAddress={setSelectedAddress}
                          filterOptions={filterOptions}
                          formik={formik}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <InputLabel id="days-of-intervention-label">
                            Aide financière
                          </InputLabel>
                          <Select
                            labelId="days-of-intervention-label"
                            id="days-of-intervention"
                            value={formik.values.financialAid}
                            label="Aide financière"
                            onChange={e => {
                              formik.setFieldValue(
                                'financialAid',
                                e.target.value
                              )
                            }}
                          >
                            <MenuItem value={'APA'}>APA</MenuItem>
                            <MenuItem value={'PCH, ACTP'}>PCH, ACTP</MenuItem>
                            <MenuItem value={'ARDH'}>ARDH</MenuItem>
                            <MenuItem value={'Mutuelle, assurance'}>
                              Mutuelle
                            </MenuItem>
                            <MenuItem value={'Autre'}>Autre</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <InputLabel id="services-label">Services</InputLabel>
                          <Select
                            multiple
                            labelId="services-label"
                            id="services"
                            value={formik.values.services}
                            label="Services"
                            onChange={e => {
                              formik.setFieldValue('services', e.target.value)
                            }}
                          >
                            <MenuItem value={'bed-time'}>
                              Lever, coucher
                            </MenuItem>
                            <MenuItem value={'washing-dressing'}>
                              Toilette, habillage
                            </MenuItem>
                            <MenuItem value={'movement'}>
                              Aide à la mobilité
                            </MenuItem>
                            <MenuItem value={'rappels'}>Rappels</MenuItem>
                            <MenuItem value={'assistance-with-eating'}>
                              Aide aux repas
                            </MenuItem>
                            <MenuItem value={'food-delivery'}>Courses</MenuItem>
                            <MenuItem value={'cleaning'}>Ménage</MenuItem>
                            <MenuItem value={'laundry-ironing'}>
                              Lessive, repassage
                            </MenuItem>
                            <MenuItem value={'company-stimulation'}>
                              Compagnie, stimulation
                            </MenuItem>
                            <MenuItem value={'night-watch'}>
                              Surveillance de nuit
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} style={{ marginBottom: '20px' }}>
                        <TextField
                          fullWidth
                          id="startDate"
                          name="startDate"
                          type="date"
                          label={'Date de début estimative'}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={formik.values.startDate}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.startDate &&
                            Boolean(formik.errors.startDate)
                          }
                          helperText={
                            formik.touched.startDate && formik.errors.startDate
                          }
                        />
                      </Grid>
                      <ReferralContact
                        formik={formik}
                        urlEmail={urlEmail}
                        institution={false}
                        optionalFields={true}
                      />
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <InputLabel id="referralLink-label">
                            Lien avec le bénéficiaire
                          </InputLabel>
                          <Select
                            id="referralLink"
                            name="referralLink"
                            labelId="referralLink-label"
                            label="👨‍👩‍👧‍👦 Votre lien avec le bénéficiaire"
                            value={formik.values.referralLink}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.referralLink &&
                              Boolean(formik.errors.referralLink)
                            }
                          >
                            <MenuItem value={'Moi-même'}>Lui-même</MenuItem>
                            <MenuItem value={'Parents'}>Parent</MenuItem>
                            <MenuItem value={'Beaux-parents'}>
                              Beau-parent
                            </MenuItem>
                            <MenuItem value={'Grands-parents'}>
                              Grand-parent
                            </MenuItem>
                            <MenuItem value={'Enfant'}>Enfant</MenuItem>
                            <MenuItem value={'Conjoint'}>Conjoint</MenuItem>
                            <MenuItem value={'Frère/Soeur'}>
                              Frère/Soeur
                            </MenuItem>
                            <MenuItem value={'Patient'}>Patient</MenuItem>
                            <MenuItem value={'Autre'}>Autre</MenuItem>
                          </Select>
                          <FormHelperText>
                            <span style={{ color: theme.palette.error.main }}>
                              {formik.touched.referralLink &&
                                formik.errors.referralLink}
                            </span>
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {keycloak.hasRealmRole('edit-lead') && (
                        <>
                          <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <Typography
                              component="h3"
                              variant={isSm ? 'h6' : 'h5'}
                              color="primary"
                              display={'inline-block'}
                              lineHeight={'unset'}
                              marginLeft={'5px'}
                              marginTop={'15px'}
                              marginBottom={'0px'}
                            >
                              Informations de l'établissement
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <OrgAutocomplete
                              orgs={orgs}
                              setSelectedOrg={setSelectedOrg}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <ASAutocomplete
                              asList={asList}
                              setSelectedAS={setSelectedAS}
                              selectedAS={selectedAS}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} style={{ marginTop: '20px' }}>
                        <Typography
                          component="h3"
                          variant={isSm ? 'h6' : 'h5'}
                          color="primary"
                          display={'inline-block'}
                          lineHeight={'unset'}
                          marginLeft={'5px'}
                          marginTop={'15px'}
                          marginBottom={'0px'}
                        >
                          Informations complémentaires
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: '40px' }}>
                        <FormikTextField
                          name="additionalInformation"
                          label="Notes"
                          formik={formik}
                          multiline={true}
                        />
                      </Grid>
                      {!hasOneEmail && (
                        <Grid item xs={12} style={{ marginBottom: '10px' }}>
                          <span style={{ color: 'red' }}>
                            Veuillez renseigner au moins 1 email
                          </span>
                        </Grid>
                      )}
                      {!hasOnePhone && (
                        <Grid item xs={12} style={{ marginBottom: '10px' }}>
                          <span style={{ color: 'red' }}>
                            Veuillez renseigner au moins 1 numéro de téléphone
                          </span>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Button
                          color="primary"
                          variant="contained"
                          fullWidth
                          type="submit"
                          size={'large'}
                          disabled={
                            isSubmitting || !hasOnePhone || !hasOneEmail
                          }
                        >
                          {isSubmitting ? (
                            <CircularProgress color="secondary" />
                          ) : (
                            'Envoyer'
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Paper>
            </Grid>
          </Container>
        </ThemeProvider>
      )) || <Unauthorized />
  )
}
